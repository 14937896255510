import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const ApplicationsShow = props => (
  <RestShow {...props} hasEdit resource="applications">
    <RestFieldItem source="fullName" header="applications.fullName" />
    <RestFieldItem source="dob" header="applications.dob" />
    <RestFieldItem source="gender" header="applications.gender" />
    <RestFieldItem source="email" header="applications.email" />
    <RestFieldItem source="phone" header="applications.phone" />
    <RestFieldItem source="facebookLink" header="applications.facebookLink" />
    <RestFieldItem source="description" header="applications.description" />
    <RestFieldItem source="position" header="applications.position" />
    <RestFieldItem source="address" header="applications.address" />
    <RestFieldItem source="v" header="applications.v" />
    <RestFieldItem source="attachmentLink" header="applications.attachmentLink" />
    <RestFieldItem source="attachmentLink2" header="applications.attachmentLink2" />
    <RestFieldItem source="recruitmentId" header="applications.recruitmentId" />
  </RestShow>
);

export default ApplicationsShow;
