import React from 'react';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';

const IndustriesList = props => (
  <List {...props} resource="industries">
    <RestFieldItem source="name" header="industries.name" />
    <RestFieldItem source="description" header="industries.description" />
    <RestFieldItem source="isActive" header="industries.isActive" />
    <RestFieldItem source="priority" header="industries.priority" />
    <ActionGroup>
      <EditButton />
      <DeleteButton />
    </ActionGroup>
  </List>
);

IndustriesList.propTypes = {};

export default IndustriesList;
