import React from 'react';
import Create from 'containers/rest/Create';
import Form from '../components/Form';

const EventsCreate = props => (
  <Create {...props} resource="events">
    <Form />
  </Create>
);

EventsCreate.propTypes = {};

export default EventsCreate;
