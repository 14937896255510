import React from 'react';
import Edit from 'containers/rest/Edit';
import Form from '../components/Form';

const ReviewsEdit = props => (
  <Edit {...props} resource="reviews">
    <Form />
  </Edit>
);

ReviewsEdit.propTypes = {};

export default ReviewsEdit;
