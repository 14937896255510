import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';

const CompanyRequestsForm = props => (
  <div {...props}>
    <RestInputItem source="fullName" header="companyRequests.fullName" />
    <RestInputItem source="phoneNumber" header="companyRequests.phoneNumber" />
    <RestInputItem source="email" header="companyRequests.email" />
    <RestInputItem source="companyName" header="companyRequests.companyName" />
    <RestInputItem source="title" header="companyRequests.title" />
    <RestInputItem source="city" header="companyRequests.city" />
    <RestInputItem source="title_recruitment" header="companyRequests.title_recruitment" />
    <RestInputItem source="numberSlot" header="companyRequests.numberSlot" />
    <RestInputItem source="talentService" header="companyRequests.talentService" />
    <RestInputItem source="status" header="companyRequests.status" />
  </div>
);

CompanyRequestsForm.propTypes = {};

export default CompanyRequestsForm;
