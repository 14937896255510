import React from 'react';
import Create from 'containers/rest/Create';
import Form from '../components/Form';

const SalaryStepsCreate = props => (
  <Create {...props} resource="salarySteps">
    <Form />
  </Create>
);

SalaryStepsCreate.propTypes = {};

export default SalaryStepsCreate;
