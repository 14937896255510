import React from 'react';
import Create from 'containers/rest/Create';
import Form, { formatOnSubmit } from '../components/Form';

const RecruitmentsCreate = (props) => (
  <Create {...props} showModal formatOnSubmit={formatOnSubmit} noCardWrapper resource="recruitments">
    <Form />
  </Create>
);

RecruitmentsCreate.propTypes = {};

export default RecruitmentsCreate;
