'use client';

import styled from 'styled-components';

export const FooterStyles = styled.div`
  background: var(--bg-primary);
  padding: 28px 0px;
  .main-footer {
    color: #fff;
    transition: all 0.5s linear;
  }
  .section-title {
    color: var(--secondary);
  }

  .middle-footer {
    padding-top: 60px;
    padding-bottom: 60px;
    background: #ffffff;
  }


  .ant-layout-footer {
    padding: 0px !important;
  }

  .footer-logo {
    height: 35px;
  }

  .footer-divider {
    background: rgba(255, 255, 255, 0.1);
    margin-bottom: 0px;
    margin-top: 110px;
  }

  .footer-right {
    flex-wrap: wrap;
    align-items: center;
  }
  .icon-social {
    width:30px;
    height: 30px;
    border-radius: 15px;
    background-color: white;
    display:flex;
    align-items:center;
    justify-content:center;
    img { 
      width:15px;
      height:15px;
      object-fit: contain;
    }
  }

  a {
    color: var(--text-footer);
    
  }
  
  a:hover,
  a:focus {
  }

  .menu-space-item {
    .pointer:hover {
      transition: all 0.2s linear;
    }
  }

  @media only screen and (max-width: 768px) {
    .footer-left {
      width: 100%;
      padding-bottom: 10px;
      > .ant-space-item {
        display: flex;
        justify-content: center;
      }
    }
    .ant-layout-footer {
      padding: 0px;
    }
  }
  .footer-bottom {
    padding: 0px 0px 20px;
    background-color: #ffffff;
    color: #000000;

    .content-footer-wrapper {
      align-items: center;
    }

    .footer-desc {
      max-width: 500px;
      margin-right: 20px;
      padding: 20px 0px;
    }
  }
  @media only screen and (max-width: 576px) {
    padding-top: 0px;
    .footer-divider {
      margin-top: 60px;
    }
    .menu-space-item {
      gap: 4px !important;
    }
    .middle-footer {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .footer-bottom {
      flex-direction: column;
      .footer-privacy-term {
        width: 100%;
      }

      .footer-copyright {
        padding: 20px 0px;
      }
    }
  }
`;

export const FooterFirstColumnStyle = styled.div`
.footer-logo-wrapper {
  max-width: 200px;

  img {
    width: 100%;
    object-fit: contain;
  }

  .section-title {
    color: var(--secondary);
  }
}`;

export const MiddleFooterStyle  = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .section-title {
    color:var(--secondary);
  }
  .middle-footer-section {
    display: flex;
    margin-right: 50px;
    align-items: center;
    .section-title {
      margin-right: 40px;
      color:var(--secondary);
    }
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      .section-title {
        margin-right: 0px;
        margin-bottom: 20px;
      }
    }
  }
  .ic-verified {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    min-width: 100px;
  }
  @media only screen and (max-width: 576px) {
    flex-direction: column;

    .middle-footer-section {
      margin: 10px 0;
      text-align: left;
      align-items: flex-start;
    }

    .ic-verified {
      justify-content: flex-start;
      margin-top: 10px;
    }
  }
`;