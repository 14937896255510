import React from 'react';
import Edit from 'containers/rest/Edit';
import Form from '../components/Form';

const ApplicationsEdit = props => (
  <Edit {...props} resource="applications">
    <Form />
  </Edit>
);

ApplicationsEdit.propTypes = {};

export default ApplicationsEdit;
