import PublicLayout from '../../layout/PublicLayout';
import ResetPasswordContainer from '../../containers/ResetPassword';

export default function ResetPassword() {
  return (
    <PublicLayout>
      <ResetPasswordContainer />
    </PublicLayout>
  );
}
