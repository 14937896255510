import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const BannersShow = props => (
  <RestShow {...props} hasEdit resource="banners">
    <RestFieldItem source="name" header="banners.name" />
    <RestFieldItem source="url" header="banners.url" />
    <RestFieldItem source="description" header="banners.description" />
    <RestFieldItem source="priority" header="banners.priority" />
    <RestFieldItem source="isActive" header="banners.isActive" />
  </RestShow>
);

export default BannersShow;
