import React from 'react';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';

const BannersList = props => (
  <List {...props} resource="banners">
    <RestFieldItem source="name" header="banners.name" />
    <RestFieldItem source="url" header="banners.url" />
    <RestFieldItem source="description" header="banners.description" />
    <RestFieldItem source="priority" header="banners.priority" />
    <RestFieldItem source="isActive" header="banners.isActive" />
    <ActionGroup>
      <EditButton />
      <DeleteButton />
    </ActionGroup>
  </List>
);

BannersList.propTypes = {};

export default BannersList;
