import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';
import { Col, Card, Row } from 'antd';
import { useSelector } from 'react-redux';
import RecruitmentInformation from './RecruitmentInformation';
import ApplicationsList from 'containers/Applications/List';
import { useParams } from 'react-router-dom';

const RecruitmentsShow = (props) => {
  const params = useParams();
  const currentData = useSelector((state) => state.recruitments.currentData);
  const initialFilter = {
    filter:{recruitmentId:params.id},
    join: ['recruitment']
  };
  return (
    <RestShow {...props} hasEdit resource="recruitments">
      <Row className="w-full" gutter={[16, 16]}>
        <Col sm={24} md={8}>
          <RecruitmentInformation record={currentData} />
        </Col>
        <Col sm={24} md={16}>
          <ApplicationsList initialFilter={initialFilter} />
        </Col>
      </Row>
    </RestShow>
  );
};

export default RecruitmentsShow;
