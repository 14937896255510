import { makeActions } from '@redux/crudCreator/actions';

export const MODEL_NAME = 'companyRequests';
export const companyRequestsActions = makeActions(
  MODEL_NAME,
  'id',
  'company-requests',
);

export const getAllCompanyRequests = companyRequestsActions.getAll;
export const editCompanyRequests = companyRequestsActions.edit;
export const createCompanyRequests = companyRequestsActions.create;
export const getByIdCompanyRequests = companyRequestsActions.getDataById;
