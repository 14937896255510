import { Card, Col, Row } from 'antd';
import PageTitle from 'components/common/PageTitle';
import i18next, { t } from 'i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
// import PagesList from 'containers/Pages/List';
import SettingStyle from './styles';

const MENUS = [
  {
    key: 'configGenerals',
    title: 'settings.configGenerals.title',
    description: 'settings.configGenerals.description',
    url: '/settings/configGenerals',
  },
  {
    key: 'banners',
    title: 'settings.banners.title',
    description: 'settings.banners.description',
    url: '/settings/banners',
  },
  {
    key: 'industries',
    title: 'settings.industries.title',
    description: 'settings.industries.description',
    url: '/settings/industries',
  },
  {
    key: 'languages',
    title: 'settings.languages.title',
    description: 'settings.languages.description',
    url: '/settings/languages',
  },
  {
    key: 'salarySteps',
    title: 'settings.salarySteps.title',
    description: 'settings.salarySteps.description',
    url: '/settings/salarySteps',
  },
  {
    key: 'workingTypes',
    title: 'settings.workingTypes.title',
    description: 'settings.workingTypes.description',
    url: '/settings/workingTypes',
  },
  {
    key: 'experienceLevels',
    title: 'settings.experienceLevels.title',
    description: 'settings.experienceLevels.description',
    url: '/settings/experienceLevels',
  },
  {
    key: 'companySizes',
    title: 'settings.companySizes.title',
    description: 'settings.companySizes.description',
    url: '/settings/companySizes',
  },
];

const Settings = () => {
  const history = useHistory();
  const onClick = (e) => {
    history.push(e.url);
  };

  return (
    <SettingStyle>
      <div className="page-header">
        <PageTitle>{i18next.t('settings.header')}</PageTitle>
      </div>
      <br />
      <Row gutter={[16, 16]}>
        {MENUS.map((e) => (
          <Col md={8} sm={12} key={e.key}>
            <Card onClick={() => onClick(e)} hoverable bordered={false}>
              <Card.Meta
                description={t(e.description, {
                  site: t(`appInfo.name`),
                })}
                title={t(e.title)}
              />
            </Card>
          </Col>
        ))}
      </Row>
    </SettingStyle>
  );
};

Settings.propTypes = {
  match: PropTypes.object,
  redirects: PropTypes.object,
  rootPath: PropTypes.string,
  initCreateData: PropTypes.object,
};

Settings.defaultProps = {
  rootPath: '/settings',
  redirects: {
    edit: 'modal',
    create: 'modal',
  },
  initCreateData: {},
};

export default Settings;
