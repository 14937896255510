import React from 'react';
import Create from 'containers/rest/Create';
import Form from '../components/Form';

const LanguagesCreate = props => (
  <Create {...props} resource="languages">
    <Form />
  </Create>
);

LanguagesCreate.propTypes = {};

export default LanguagesCreate;
