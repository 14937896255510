import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  retrieveReference as retrieveReferenceAction,
  retrieveReferenceList,
} from '@redux/referenceData/actions';
import { getRecordData, plainToFlattenObject } from 'utils/tools';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
  getReferenceResource,
  getEnabledLoadMoreReference,
} from '../../../@redux/referenceData/selectors';
import crudSelectors from '../../../@redux/crudSelectors';
import { formatFilterInSearch } from '@redux/crudCreator/dataProvider';

const ReferenceInput = (props) => {
  const {
    record,
    children,
    source,
    fieldKey,
    resource,
    initialFilter,
    hasAdd,
    defaultOptions,
    formatSearch = (text, key) => ({
      [key]: text,
    }),
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const resourceData = useSelector((state) =>
    getReferenceResource(state, props),
  );

  const referenceFilter = useSelector(
    (state) => state.reference?.[resource]?.filter,
  );

  const loadingData = useSelector((state) =>
    crudSelectors[props.resource].getLoading(state, props),
  );
  const enabledLoadMore = useSelector((state) =>
    getEnabledLoadMoreReference(state, props),
  );
  const retrieveReference = (data, initialFilter = {}) => {
    return dispatch(
      retrieveReferenceAction({
        resource: props.resource,
        ids: Array.isArray(data)
          ? data.map((e) => e?.id || e)
          : [data?.id || data],
        mappedBy: props.mappedBy,
        options: { ...defaultOptions, ...initialFilter },
      }),
    );
  };

  const retrieveList = (filter, isRefresh) =>
    dispatch(
      retrieveReferenceList({
        resource,
        data: {
          ...props.initialFilter,
          ...filter,
        },
        options: {
          isRefresh,
          ...defaultOptions,
        },
      }),
    );
  // dispatch(
  //   CRUDActions[props.resource].getAll({
  //     data: {
  //       ...props.initialFilter,
  //       ...filter,
  //     },
  //     options: { isRefresh },
  //   }),
  // );

  const onSearch = useCallback((value) => {
    const { searchKey, searchKeys, initialFilter } = props;
    if (searchKey || searchKeys) {
      retrieveList(
        searchKeys
          ? {
              q: {
                $and: [
                  {
                    $or: searchKeys.map((e) => ({
                      [e]: {
                        $contL: value,
                      },
                    })),
                  },
                  {
                    $and:
                      formatFilterInSearch(initialFilter?.filter || {}) || [],
                  },
                ],
              },
            }
          : formatSearch(value, searchKey),
        true,
      );
    }
    // eslint-disable-next-line
  }, []);

  const debouceSearch = _.debounce(onSearch, 300);

  const retrieveListWaypoint = () => {
    console.log('referenceFilter', referenceFilter);
    if (enabledLoadMore) {
      retrieveList(referenceFilter?.filter);
    }
  };

  const goCreateResource = () => {
    history.push(`#${resource}/create`);
  };

  useEffect(() => {
    console.log(retrieveReference);
    if (getRecordData(record, source)) {
      retrieveReference(getRecordData(record, source), initialFilter);
    }
    !loadingData &&
      retrieveList(
        initialFilter
          ? {
              ...initialFilter,
              // or: getRecordData(record, source)
              //   ? `id||$eq||${getRecordData(record, source)}`
              //   : undefined,
            }
          : {
              // or: getRecordData(record, source)
              //   ? `id||$eq||${getRecordData(record, source)}`
              //   : undefined,
              limit: 10,
              offset: 0,
            },
        true,
      );
    // eslint-disable-next-line
  }, [record, initialFilter, resource]);

  const newChildren = React.cloneElement(children, {
    onSearch: (value) => {
      debouceSearch(value);
    },
    onEnter: () => retrieveListWaypoint(),
    record,
    fieldKey,
    loading: loadingData,
    form,
    source,
    resourceData,
    resource,
    isFilterOption: false,
    subfix: hasAdd ? (
      <Button
        size="small"
        style={{ marginLeft: 10 }}
        type="primary"
        onClick={goCreateResource}
        icon={<PlusOutlined />}
      />
    ) : null,
  });
  return newChildren;
};

const isEqual = (pre, newProps) => {
  if (checkJSONEqual(pre, newProps)) return true;

  return false;
};

const checkJSONEqual = (a, b) => {
  const old = plainToFlattenObject(_.omit(a, ['children', 'form']), true);
  const newObj = plainToFlattenObject(_.omit(b, ['children', 'form']), true);
  let isEqual = true;
  const fullProps = { ...old, ...newObj };
  Object.keys(fullProps).forEach((key) => {
    if (old[key] !== newObj[key]) {
      isEqual = false;
    }
  });
  return isEqual;
};

ReferenceInput.propTypes = {
  resource: PropTypes.string.isRequired,
  resourceData: PropTypes.array,
  record: PropTypes.object,
  retrieveList: PropTypes.func,
  children: PropTypes.node,
  source: PropTypes.string,
  retrieveReference: PropTypes.func,
  form: PropTypes.object,
  searchKey: PropTypes.string,
  enabledLoadMore: PropTypes.bool,
  loadingData: PropTypes.bool,
  initialFilter: PropTypes.object,
  hasAdd: PropTypes.bool,
};

const RestInputMemo = React.memo(ReferenceInput, isEqual);
const RestReferenceInput = (props) => (
  <RestInputContext.Consumer>
    {({ record, form }) => (
      <RestInputMemo {...props} form={form} record={record} />
    )}
  </RestInputContext.Consumer>
);

export default RestReferenceInput;
