import React from 'react';
import Create from 'containers/rest/Create';
import Form from '../components/Form';

const CompanyRequestsCreate = props => (
  <Create {...props} resource="companyRequests">
    <Form />
  </Create>
);

CompanyRequestsCreate.propTypes = {};

export default CompanyRequestsCreate;
