import RestInputItem from 'components/RestInput/RestInputItem';
import { Input, InputNumber, Switch } from 'antd';

const IndustriesForm = () => (
  <div>
    <RestInputItem source="name" header="industries.name" />
    <RestInputItem ContentComponent={Input.TextArea} source="description" header="industries.description" />
    <RestInputItem
      ruleType="number"
      ContentComponent={InputNumber}
      source="priority"
      header="industries.priority"
    />
    <RestInputItem
      valuePropName="checked"
      ruleType="boolean"
      ContentComponent={Switch}
      source="isActive"
      header="industries.isActive"
    />
  </div>
);

IndustriesForm.propTypes = {};

export default IndustriesForm;
