import { combineReducers } from 'redux';
import auth from './auth/slice';
import modal from './modal/slice';
// import here
import companyRequests from './companyRequests/slice';
import applications from './applications/slice';
import companySizes from './companySizes/slice';
import industries from './industries/slice';
import events from './events/slice';
import configGenerals from './configGenerals/slice';
import banners from './banners/slice';
import experienceLevels from './experienceLevels/slice';
import languages from './languages/slice';
import salarySteps from './salarySteps/slice';
import recruitments from './recruitments/slice';
import workingTypes from './workingTypes/slice';
import reviews from './reviews/slice';
import companies from './companies/slice';
import users from './users/slice';
import reference from './referenceData/slice';
import config from './config/slice';

export default () =>
  combineReducers({
    auth,
    modal,
    config,
    reference,
    // add reducer here
    companyRequests,
    applications,
    companySizes,
    industries,
    events,
    configGenerals,
    banners,
    experienceLevels,
    languages,
    salarySteps,
    recruitments,
    workingTypes,
    reviews,
    companies,
    users,
  });
