import React from 'react';
import Edit from 'containers/rest/Edit';
import Form from '../components/Form';

const ExperienceLevelsEdit = props => (
  <Edit {...props} resource="experienceLevels">
    <Form />
  </Edit>
);

ExperienceLevelsEdit.propTypes = {};

export default ExperienceLevelsEdit;
