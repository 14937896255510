import { Typography } from 'antd';
import JobCardStyles from './styles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { dateFromNow, formatMoney } from 'utils/textUtils';

export default function JobCard({ hasButton = true, record }) {
  const { t } = useTranslation();
  return (
    <Link className='w-full h-full' to={`/recruitments/${record?.id}/show`}>
      <JobCardStyles className='w-full'>
        <div className="d-flex flex-wrap">
          <div className="flex-1">
            <Typography.Paragraph ellipsis={{rows:2}} className="job-card-title">{record?.header}</Typography.Paragraph>
            <Typography.Paragraph  ellipsis={{rows:1}}  className="job-card-company">
              {record?.company?.name}
            </Typography.Paragraph>
            <div className="job-card-address">{record?.city}</div>
            <div className="salary-label">
              <img className="salary-icon" src="/icons/salary.png" />
              <span>{record?.minSalary!==record?.maxSalary &&record?.maxSalary? ` ${formatMoney(record?.minSalary)} - ${formatMoney(record?.maxSalary)} + 5% doanh thu`: ` ${formatMoney(record?.minSalary)} + ${record?.salaryKpi}`}</span>
            </div>
            <div className="job-card-time d-flex">
              <div>{dateFromNow(record.createdAt)}</div>
              <div className="dot">·</div>
              <div>Xem thêm...</div>
            </div>
          </div>
        </div>
      </JobCardStyles>
    </Link>
  );
}
