import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const IndustriesShow = props => (
  <RestShow {...props} hasEdit resource="industries">
    <RestFieldItem source="name" header="industries.name" />
    <RestFieldItem source="description" header="industries.description" />
    <RestFieldItem source="isActive" header="industries.isActive" />
    <RestFieldItem source="priority" header="industries.priority" />
  </RestShow>
);

export default IndustriesShow;
