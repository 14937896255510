import React from 'react';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';

const WorkTypesList = (props) => (
  <List {...props} resource="workingTypes">
    <RestFieldItem source="name" header="workingTypes.name" />
    <RestFieldItem source="description" header="workingTypes.description" />
    <RestFieldItem source="priority" header="workingTypes.priority" />
    <RestFieldItem source="isActive" header="workingTypes.isActive" />
    <ActionGroup>
      <EditButton />
      <DeleteButton />
    </ActionGroup>
  </List>
);

WorkTypesList.propTypes = {};

export default WorkTypesList;
