import { FooterFirstColumnStyle } from './styles';

export default function FooterFirstColumn() {
  return (
    <FooterFirstColumnStyle>
      <a href="/">
        <div className="footer-logo-wrapper">
          <img loading="lazy" alt="logo" src="/static/images/logo-white.png" />
        </div>
      </a>
    </FooterFirstColumnStyle>
  );
}
