import List from 'containers/rest/List';
import JobCard from '../components/JobCard';

const initialFilter = {
  join: ['company'],
};
const RecruitmentsList = (props) => (
  <List
    {...props}
    grid={{
      gutter: 16,
      xs: 1,
      sm: 2,
      md: 2,
      lg: 2,
      xl: 2,
      xxl: 2,
    }}
    isList
    searchKeys={['header', 'title']}
    redirects={{}}
    initialFilter={initialFilter}
    resource="recruitments"
  >
    <JobCard />
  </List>
);

RecruitmentsList.propTypes = {};

export default RecruitmentsList;
