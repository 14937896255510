import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const ConfigGeneralsShow = props => (
  <RestShow {...props} hasEdit resource="configGenerals">
    <RestFieldItem source="key" header="configGenerals.key" />
    <RestFieldItem source="value" header="configGenerals.value" />
    <RestFieldItem source="isActive" header="configGenerals.isActive" />
  </RestShow>
);

export default ConfigGeneralsShow;
