import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import DeleteButton from 'components/RestActions/DeleteButton';
import {
  FacebookFilled,
  FileTextOutlined,
  MailFilled,
  PhoneFilled,
} from '@ant-design/icons';
import { GENDER } from 'configs/localData';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from 'utils/textUtils';
import { Switch } from 'antd';

const ApplicationsList = (props) => {
  const { t } = useTranslation();
  return (
    <List
      initialFilter={{ join: ['recruitment.company'] }}
      {...props}
      hasSearch={false}
      hasCreate={false}
      layoutButtonCreate="inline"
      exportExcelQuery="/admin/applications/export-excel"
      resource="applications"
      isExportWithFilter
    >
      <RestFieldItem
        width={100}
        format={(d, r) => (
          <div>
            <div>
              <b>{d}</b>
            </div>
            <i>{t(GENDER.find((e) => e.value === r?.gender)?.text)}</i>
          </div>
        )}
        source="fullName"
        header="applications.fullName"
      />
      <RestFieldItem width={100} source="dob" header="applications.dob" />
      <RestFieldItem
        format={(d, r) => (
          <div>
            <div className="d-flex">
              <MailFilled className="mr-10" />
              <b>{d}</b>
            </div>
            {!!r.phone && (
              <div className="d-flex">
                <PhoneFilled className="mr-10" />
                <b>{r.phone}</b>
              </div>
            )}
            {!!r.phone && (
              <a href={r.facebookLink} target="__blank">
                <div className="d-flex">
                  <FacebookFilled className="mr-10" />
                  link
                </div>
              </a>
            )}
          </div>
        )}
        width={200}
        source="email"
        header="applications.contact"
      />
      <RestFieldItem
        width={100}
        source="recruitment.title"
        header="applications.position"
      />
      <RestFieldItem
        width={150}
        source="address"
        header="applications.address"
      />
      <RestFieldItem
        width={150}
        source="recruitment.company.name"
        header="applications.company"
      />
      <RestFieldItem
        format={(d) => (
          <a href={d} target="__blank">
            <FileTextOutlined />
          </a>
        )}
        width={100}
        source="attachmentLink"
        header="applications.attachmentLink"
      />
      <RestFieldItem
        width={100}
        format={(d) => formatDateTime(d)}
        source="createdAt"
        header="applications.applicationTime"
      />
      <RestFieldItem
        width={100}
        valueProp="checked"
        component={<Switch />}
        source="isOpened"
        header="applications.isOpened"
      />
    </List>
  );
};

ApplicationsList.propTypes = {};

export default ApplicationsList;
