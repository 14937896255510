import React from 'react';
import { Typography } from 'antd';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';

const ConfigGeneralsList = (props) => (
  <List
    {...props}
    rootPath="/settings"
    redirects={{ edit: 'modal', create: 'modal' }}
    resource="configGenerals"
  >
    <RestFieldItem source="key" header="configGenerals.key" />
    <RestFieldItem
      format={(d) => (
        <Typography.Paragraph ellipsis={{ rows: 2 }}>{d}</Typography.Paragraph>
      )}
      source="value"
      header="configGenerals.value"
    />
    <RestFieldItem source="isActive" header="configGenerals.isActive" />
    <ActionGroup>
      <EditButton />
      <DeleteButton />
    </ActionGroup>
  </List>
);

ConfigGeneralsList.propTypes = {};

export default ConfigGeneralsList;
