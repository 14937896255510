import { groupBy, values } from 'lodash';
import countries from './countries.json';
import cities from './world-cities_json.json';
import Nationalities from './nationalities';
import vietnameCities from './vietnamCities.json';

export const COUNTRIES = values(countries.countries);
export const CITIES = groupBy(cities, 'country');
export const NATIONALITIES = Nationalities;
export const VIETNAME_CITIES=vietnameCities.map(e=>({name:e}))
export const WEEKS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const SALARY_PERIOD= [{
  value:'month',
  text:'Theo Tháng'
},{
  value:'hour',
  text:'Theo Giờ'
},{
  value:'custom',
  text:'Theo Thảo thuận'
}]

export const SALARY_TYPES = [
  {
    value: 'fixed',
    text: 'Lương cứng',
  },
  {
    value: 'range',
    text: 'Lương cứng trong khoản',
  },
];
