export const ICCredit = (props) => (
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.67857C0 1.20332 1.2083 0 2.68966 0H11.6552C13.1365 0 14.3448 1.20332 14.3448 2.67857V10.7143C14.3448 11.2074 13.9434 11.6071 13.4483 11.6071C12.9531 11.6071 12.5517 11.2074 12.5517 10.7143V2.67857C12.5517 2.18954 12.1462 1.78571 11.6552 1.78571H2.68966C2.1986 1.78571 1.7931 2.18954 1.7931 2.67857V20.5357C1.7931 21.0247 2.1986 21.4286 2.68966 21.4286H4.48276C4.97791 21.4286 5.37931 21.8283 5.37931 22.3214C5.37931 22.8145 4.97791 23.2143 4.48276 23.2143H2.68966C1.2083 23.2143 0 22.011 0 20.5357V2.67857Z"
      fill="#2C3546"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.48276 0C4.97791 0 5.37931 0.399746 5.37931 0.892857V2.05357C5.37931 2.36403 5.69515 2.67857 6.0069 2.67857H8.33793C8.64968 2.67857 8.96552 2.36403 8.96552 2.05357V0.892857C8.96552 0.399746 9.36692 0 9.86207 0C10.3572 0 10.7586 0.399746 10.7586 0.892857V2.05357C10.7586 3.35025 9.63998 4.46429 8.33793 4.46429H6.0069C4.70485 4.46429 3.58621 3.35025 3.58621 2.05357V0.892857C3.58621 0.399746 3.98761 0 4.48276 0Z"
      fill="#2C3546"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.27586 11.6071C5.78481 11.6071 5.37931 12.011 5.37931 12.5V22.3214C5.37931 22.8105 5.78481 23.2143 6.27586 23.2143H23.3103C23.8014 23.2143 24.2069 22.8105 24.2069 22.3214V12.5C24.2069 12.011 23.8014 11.6071 23.3103 11.6071H6.27586ZM3.58621 12.5C3.58621 11.0247 4.7945 9.82143 6.27586 9.82143H23.3103C24.7917 9.82143 26 11.0247 26 12.5V22.3214C26 23.7967 24.7917 25 23.3103 25H6.27586C4.7945 25 3.58621 23.7967 3.58621 22.3214V12.5Z"
      fill="#2C3546"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.58621 14.2857C3.58621 13.7926 3.98761 13.3929 4.48276 13.3929H25.1034C25.5986 13.3929 26 13.7926 26 14.2857C26 14.7788 25.5986 15.1786 25.1034 15.1786H4.48276C3.98761 15.1786 3.58621 14.7788 3.58621 14.2857Z"
      fill="#2C3546"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1379 19.6429C16.1379 18.6566 16.9407 17.8571 17.931 17.8571C18.9213 17.8571 19.7241 18.6566 19.7241 19.6429C19.7241 20.6291 18.9213 21.4286 17.931 21.4286C16.9407 21.4286 16.1379 20.6291 16.1379 19.6429Z"
      fill="#2C3546"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6483 19.6429C18.6483 18.6566 19.4511 17.8571 20.4414 17.8571C21.4317 17.8571 22.2345 18.6566 22.2345 19.6429C22.2345 20.6291 21.4317 21.4286 20.4414 21.4286C19.4511 21.4286 18.6483 20.6291 18.6483 19.6429Z"
      fill="#2C3546"
    />
  </svg>
);
