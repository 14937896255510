import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';

const ApplicationsForm = props => (
  <div {...props}>
    <RestInputItem source="fullName" header="applications.fullName" />
    <RestInputItem source="dob" header="applications.dob" />
    <RestInputItem source="gender" header="applications.gender" />
    <RestInputItem source="email" header="applications.email" />
    <RestInputItem source="phone" header="applications.phone" />
    <RestInputItem source="facebookLink" header="applications.facebookLink" />
    <RestInputItem source="description" header="applications.description" />
    <RestInputItem source="position" header="applications.position" />
    <RestInputItem source="address" header="applications.address" />
    <RestInputItem source="v" header="applications.v" />
    <RestInputItem source="attachmentLink" header="applications.attachmentLink" />
    <RestInputItem source="attachmentLink2" header="applications.attachmentLink2" />
    <RestInputItem source="recruitmentId" header="applications.recruitmentId" />
  </div>
);

ApplicationsForm.propTypes = {};

export default ApplicationsForm;
