import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';

const ReviewsForm = props => (
  <div {...props}>
    <RestInputItem source="comment" header="reviews.comment" />
    <RestInputItem source="title" header="reviews.title" />
    <RestInputItem source="rating" header="reviews.rating" />
    <RestInputItem source="isActive" header="reviews.isActive" />
    <RestInputItem source="priority" header="reviews.priority" />
  </div>
);

ReviewsForm.propTypes = {};

export default ReviewsForm;
