import React from 'react';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';

const EventsList = props => (
  <List {...props} resource="events">
    <RestFieldItem source="title" header="events.title" />
    <RestFieldItem source="description" header="events.description" />
    <RestFieldItem source="priority" header="events.priority" />
    <RestFieldItem source="link" header="events.link" />
    <RestFieldItem source="thumbnail" header="events.thumbnail" />
    <RestFieldItem source="isActive" header="events.isActive" />
    <RestFieldItem source="startDate" header="events.startDate" />
    <RestFieldItem source="endDate" header="events.endDate" />
    <RestFieldItem source="location" header="events.location" />
    <RestFieldItem source="city" header="events.city" />
    <ActionGroup>
      <EditButton />
      <DeleteButton />
    </ActionGroup>
  </List>
);

EventsList.propTypes = {};

export default EventsList;
