import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';
import { Card, Input, Switch, Row, Col } from 'antd';
import RestReferenceInput from 'containers/rest/ReferenceInput';
import RestSelect from 'components/RestInput/RestSelect';
import RestAvatarInput from 'components/RestInput/RestAvatarInput';
import styled from 'styled-components';
import RestEditor from 'components/RestInput/RestEditor';
const CompaniesFormStyle = styled.div`
  .logoUrl {
    .ant-upload {
      width:100%  !important;
      height:200px  !important;
    }
    .overlay {
      width:100%  !important;
      height:200px  !important;
    }
  }

  .coverUrl {
    .ant-upload {
      width:100% !important;
      height:200px  !important;
    } 
    .overlay {
      width:100%  !important;
      height:200px  !important;
    }
  }
`;

const CompaniesForm = (props) => (
  <CompaniesFormStyle>
  <Card>
    <Row gutter={[16, 16]}>
      <Col md={4}>
        <RestAvatarInput className='logoUrl' header="companies.logoUrl" source="logoUrl" />
      </Col>
      <Col md={20}>
        <RestAvatarInput isFullWidth className='coverUrl' header="companies.coverUrl" source="coverUrl" />
      </Col>
    </Row>

    <RestInputItem source="name" header="companies.name" />
    <RestEditor
      ContentComponent={Input.TextArea}
      source="description"
      header="companies.description"
    />
    <RestInputItem source="address" header="companies.address" />
    <RestInputItem source="phoneNumber" header="companies.phoneNumber" />
    <RestInputItem source="email" header="companies.email" />
    <RestInputItem source="website" header="companies.website" />
    <RestInputItem  source="industryId" isReference>
      <RestReferenceInput resource="industries" source="industryId">
        <RestSelect
          header="companies.industry"
          source="industryId"
          valueProp="id"
          titleProp="name"
        />
      </RestReferenceInput>
    </RestInputItem>
    <RestInputItem source="companySizeId" isReference>
      <RestReferenceInput
        defaultOptions={{ customApiResource: 'company-sizes' }}
        resource="companySizes"
        source="companySizeId"
      >
        <RestSelect
          header="companies.companySizeId"
          source="companySizeId"
          valueProp="id"
          titleProp="name"
        />
      </RestReferenceInput>
    </RestInputItem>
    <RestInputItem
      ruleType="boolean"
      ContentComponent={Switch}
      valuePropName="checked"
      source="isActive"
      header="companies.isActive"
    />
  </Card></CompaniesFormStyle>
);

CompaniesForm.propTypes = {};

export default CompaniesForm;
