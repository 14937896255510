import React from 'react';
import Edit from 'containers/rest/Edit';
import Form from '../components/Form';

const IndustriesEdit = props => (
  <Edit {...props} resource="industries">
    <Form />
  </Edit>
);

IndustriesEdit.propTypes = {};

export default IndustriesEdit;
