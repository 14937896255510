import React from 'react';
import Create from 'containers/rest/Create';
import Form from '../components/Form';

const IndustriesCreate = props => (
  <Create {...props} resource="industries">
    <Form />
  </Create>
);

IndustriesCreate.propTypes = {};

export default IndustriesCreate;
