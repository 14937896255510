import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const CompanyRequestsShow = props => (
  <RestShow {...props} hasEdit resource="companyRequests">
    <RestFieldItem source="fullName" header="companyRequests.fullName" />
    <RestFieldItem source="phoneNumber" header="companyRequests.phoneNumber" />
    <RestFieldItem source="email" header="companyRequests.email" />
    <RestFieldItem source="companyName" header="companyRequests.companyName" />
    <RestFieldItem source="title" header="companyRequests.title" />
    <RestFieldItem source="city" header="companyRequests.city" />
    <RestFieldItem source="title_recruitment" header="companyRequests.title_recruitment" />
    <RestFieldItem source="numberSlot" header="companyRequests.numberSlot" />
    <RestFieldItem source="talentService" header="companyRequests.talentService" />
    <RestFieldItem source="status" header="companyRequests.status" />
  </RestShow>
);

export default CompanyRequestsShow;
