import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const LanguagesShow = props => (
  <RestShow {...props} hasEdit resource="languages">
    <RestFieldItem source="name" header="languages.name" />
    <RestFieldItem source="description" header="languages.description" />
    <RestFieldItem source="priority" header="languages.priority" />
    <RestFieldItem source="isActive" header="languages.isActive" />
  </RestShow>
);

export default LanguagesShow;
