import common from './common.json';
import users from './users.json';
import settings from './settings.json';
import companies from './companies.json';
import banners from './banners.json';
import configGenerals from './configGenerals.json';
import events from './events.json';
import experienceLevels from './experienceLevels.json';
import industries from './industries.json';
import languages from './languages.json';
import recruitments from './recruitments.json';
import reviews from './reviews.json';
import salarySteps from './salarySteps.json';
import workTypes from './workTypes.json';
import companySizes from './companySizes.json';
import applications from './applications.json';
import companyRequests from './companyRequests.json';

export default {
  ...common,
  ...users,
  ...companies,
  ...banners,
  ...configGenerals,
  ...events,
  ...experienceLevels,
  ...industries,
  ...languages,
  ...recruitments,
  ...reviews,
  ...salarySteps,
  ...workTypes,
  ...companySizes,
  ...applications,
  ...companyRequests,
  settings,
};
