import Modal from 'components/common/Modal';
import CompanyRequests from 'pages/CompanyRequests';
import Applications from 'pages/Applications';
import CompanySizes from 'pages/CompanySizes';
import Industries from 'pages/Industries';
import Events from 'pages/Events';
import ConfigGenerals from 'pages/ConfigGenerals';
import Banners from 'pages/Banners';
import ExperienceLevels from 'pages/ExperienceLevels';
import Languages from 'pages/Languages';
import SalarySteps from 'pages/SalarySteps';
import Recruitments from 'pages/Recruitments';
import WorkingTypes from 'pages/WorkingTypes';
import Reviews from 'pages/Reviews';
import Companies from 'pages/Companies';
import Users from 'pages/Users';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const modalRoutes = [

  {
    path: '/companyRequests',
    routes: [
      {
        path: '/create',
        component: CompanyRequests.Create,
      },
      {
        path: '/edit',
        component: CompanyRequests.Edit,
      },
    ],
  },

  {
    path: '/applications',
    routes: [
      {
        path: '/create',
        component: Applications.Create,
      },
      {
        path: '/edit',
        component: Applications.Edit,
      },
    ],
  },

  {
    path: '/companySizes',
    routes: [
      {
        path: '/create',
        component: CompanySizes.Create,
      },
      {
        path: '/edit',
        component: CompanySizes.Edit,
      },
    ],
  },
  {
    path: '/industries',
    routes: [
      {
        path: '/create',
        component: Industries.Create,
      },
      {
        path: '/edit',
        component: Industries.Edit,
      },
    ],
  },
  {
    path: '/events',
    routes: [
      {
        path: '/create',
        component: Events.Create,
      },
      {
        path: '/edit',
        component: Events.Edit,
      },
    ],
  },

  {
    path: '/configGenerals',
    routes: [
      {
        path: '/create',
        component: ConfigGenerals.Create,
      },
      {
        path: '/edit',
        component: ConfigGenerals.Edit,
      },
    ],
  },

  {
    path: '/banners',
    routes: [
      {
        path: '/create',
        component: Banners.Create,
      },
      {
        path: '/edit',
        component: Banners.Edit,
      },
    ],
  },

  {
    path: '/experienceLevels',
    routes: [
      {
        path: '/create',
        component: ExperienceLevels.Create,
      },
      {
        path: '/edit',
        component: ExperienceLevels.Edit,
      },
    ],
  },

  {
    path: '/languages',
    routes: [
      {
        path: '/create',
        component: Languages.Create,
      },
      {
        path: '/edit',
        component: Languages.Edit,
      },
    ],
  },

  {
    path: '/salarySteps',
    routes: [
      {
        path: '/create',
        component: SalarySteps.Create,
      },
      {
        path: '/edit',
        component: SalarySteps.Edit,
      },
    ],
  },

  {
    path: '/recruitments',
    routes: [
      {
        path: '/create',
        component: Recruitments.Create,
      },
      {
        path: '/edit',
        component: Recruitments.Edit,
      },
    ],
  },

  {
    path: '/workingTypes',
    routes: [
      {
        path: '/create',
        component: WorkingTypes.Create,
      },
      {
        path: '/edit',
        component: WorkingTypes.Edit,
      },
    ],
  },

  {
    path: '/reviews',
    routes: [
      {
        path: '/create',
        component: Reviews.Create,
      },
      {
        path: '/edit',
        component: Reviews.Edit,
      },
    ],
  },

  {
    path: '/companies',
    routes: [
      {
        path: '/create',
        component: Companies.Create,
      },
      {
        path: '/edit',
        component: Companies.Edit,
      },
    ],
  },
  {
    path: '/users',
    routes: [
      {
        path: '/create',
        component: Users.Create,
      },
      {
        path: '/edit',
        component: Users.Edit,
      },
    ],
  },
];

let modal = null;

const getModalRoute = (currentModal) => {
  const modalRoute =
    currentModal &&
    modalRoutes.find((route) => currentModal.search(route.path) > -1);
  if (modalRoute) {
    return modalRoute.routes.find(
      (route) => currentModal.indexOf(route.path) > -1,
    );
  }
  return modalRoute;
};

const ModalRoute = () => {
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (location.hash && location.hash !== '#') {
      const modelRoute = location.hash.replace('#', '/');
      modal = getModalRoute(modelRoute);
    }
    // eslint-disable-next-line
  }, [location.hash]);
  const closeModal = () => {
    history.replace(`${location.pathname}${location.search}`);
  };

  const modelRoute = location.hash.replace('#', '/');
  modal = getModalRoute(modelRoute) || modal;
  const modalOptions = modal && modal?.modalOptions ? modal?.modalOptions : {};
  return (
    <Modal
      {...modalOptions}
      open={!!(location.hash && location.hash !== '#')}
      footer={null}
      onCancel={closeModal}
      onClose={closeModal}
    >
      {modal?.component && (
        <modal.component
          showModal
          visibleModal={!!(location.hash && location.hash !== '#')}
          location={location}
        />
      )}
    </Modal>
  );
};

ModalRoute.propTypes = {
  location: PropTypes.object,
  currentModal: PropTypes.string,
  closeModal: PropTypes.func,
  showModal: PropTypes.func,
  replaceRoute: PropTypes.func,
};

export default ModalRoute;
