import React from 'react';
import Create from 'containers/rest/Create';
import Form from '../components/Form';

const ConfigGeneralsCreate = props => (
  <Create {...props} resource="configGenerals">
    <Form />
  </Create>
);

ConfigGeneralsCreate.propTypes = {};

export default ConfigGeneralsCreate;
