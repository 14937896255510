import React from 'react';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';

const ReviewsList = props => (
  <List {...props} resource="reviews">
    <RestFieldItem source="comment" header="reviews.comment" />
    <RestFieldItem source="title" header="reviews.title" />
    <RestFieldItem source="rating" header="reviews.rating" />
    <RestFieldItem source="isActive" header="reviews.isActive" />
    <RestFieldItem source="priority" header="reviews.priority" />
    <ActionGroup>
      <EditButton />
      <DeleteButton />
    </ActionGroup>
  </List>
);

ReviewsList.propTypes = {};

export default ReviewsList;
