import defaultTheme from './default';

export const antTheme = {
  token: {
    colorPrimary: defaultTheme.palette.primary,
  },
  components:{
    Card:{
      colorBorder:'#00296B',
    },
  },
};

export default defaultTheme;
