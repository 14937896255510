import { Row, Col } from 'antd';
import FooterFirstColumn from './FooterFirstColumn';
import FooterMenuColumn from './FooterMenuColumn';

const MENUS = [{
  name: "Khám phá",
  subMenus:[
    {name: 'Về chúng tôi', path: '/help-center/questions/46'},
    {name: 'Đối tác Talentmate.vn', path: '/help-center/questions/46'},
    {name: 'Sự kiện sắp tới', path: '/help-center/questions/46'},
    {name: 'Các câu hỏi thường gặp', path: '/help-center/questions/46'},
  ],
  },{
    name: "Thông tin liên hệ",
    subMenus:[
      {name: 'Tầng 8, 267 Hoàng Diệu, Hải Châu, TP. Đà Nẵng', path: '/help-center/questions/46'},
      {name: 'Gmail: talentmatevn@gmail.com', path: '/help-center/questions/46'},
      {name: 'Hotline: 0934985909', path: '/help-center/questions/46'},
    ],
    }]

export default function FooterMenus({
  generalConfig,
  middleFooterMenusData,
  bottomFooterMenusData,
}) {
  return (
    <Row gutter={[24, 24]} className="footer-row-content">
      <Col lg={10} md={10} sm={12} xs={24}>
        <FooterFirstColumn
          generalConfig={generalConfig}
          middleFooterMenusData={middleFooterMenusData}
          bottomFooterMenusData={bottomFooterMenusData}
        />
      </Col>
      <Col lg={14} md={14} sm={24} xs={24}>
        <Row gutter={[30, 44]}>
          {MENUS?.map((e, i) => (
            <FooterMenuColumn isShowFollowUs={i===MENUS.length-1} key={`item-${String(i)}`} menu={e} />
          ))}
        </Row>
      </Col>
    </Row>
  );
}
