import React from 'react';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';

const CompanySizesList = props => (
  <List {...props} resource="companySizes">
    <RestFieldItem source="name" header="companySizes.name" />
    <RestFieldItem source="description" header="companySizes.description" />
    <ActionGroup>
      <EditButton />
      <DeleteButton />
    </ActionGroup>
  </List>
);

CompanySizesList.propTypes = {};

export default CompanySizesList;
