import React from 'react';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';

const ExperienceLevelsList = props => (
  <List {...props} resource="experienceLevels">
    <RestFieldItem source="name" header="experienceLevels.name" />
    <RestFieldItem source="description" header="experienceLevels.description" />
    <RestFieldItem source="priority" header="experienceLevels.priority" />
    <RestFieldItem source="isActive" header="experienceLevels.isActive" />
    <ActionGroup>
      <EditButton />
      <DeleteButton />
    </ActionGroup>
  </List>
);

ExperienceLevelsList.propTypes = {};

export default ExperienceLevelsList;
