import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const CompaniesShow = props => (
  <RestShow {...props} hasEdit resource="companies">
    <RestFieldItem source="name" header="companies.name" />
    <RestFieldItem source="description" header="companies.description" />
    <RestFieldItem source="address" header="companies.address" />
    <RestFieldItem source="phoneNumber" header="companies.phoneNumber" />
    <RestFieldItem source="website" header="companies.website" />
    <RestFieldItem source="isActive" header="companies.isActive" />
  </RestShow>
);

export default CompaniesShow;
