import React from 'react';
import Edit from 'containers/rest/Edit';
import Form from '../components/Form';

const BannersEdit = props => (
  <Edit {...props} resource="banners">
    <Form />
  </Edit>
);

BannersEdit.propTypes = {};

export default BannersEdit;
