export const ICRollback = (props) => (
  <svg
    width="28"
    height="24"
    viewBox="0 0 28 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28 6.52V22.4C28 23.28 27.3 24 26.4444 24H3.53889C2.68333 24 1.98333 23.28 1.98333 22.4C1.98333 21.52 2.68333 20.8 3.53889 20.8H24.8889V8H5.32778L7.46667 10.24C8.08889 10.88 8.08889 11.92 7.46667 12.56C7.15556 12.88 6.76667 13.04 6.37778 13.04C5.98889 13.04 5.6 12.88 5.28889 12.56L0.466667 7.64C0.155555 7.32 0 6.92 0 6.52C0 6.08 0.155555 5.68 0.466667 5.4L5.28889 0.48C5.91111 -0.16 6.88333 -0.16 7.50556 0.48C8.12778 1.12 8.08889 2.08 7.50556 2.72L5.32778 4.8H26.4444C27.3 4.8 28 5.64 28 6.52Z"
      fill="#2C3546"
    />
  </svg>
);
