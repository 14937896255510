import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const ReviewsShow = props => (
  <RestShow {...props} hasEdit resource="reviews">
    <RestFieldItem source="comment" header="reviews.comment" />
    <RestFieldItem source="title" header="reviews.title" />
    <RestFieldItem source="rating" header="reviews.rating" />
    <RestFieldItem source="isActive" header="reviews.isActive" />
    <RestFieldItem source="priority" header="reviews.priority" />
  </RestShow>
);

export default ReviewsShow;
