import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';
import {
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Switch,
} from 'antd';
import RestReferenceInput from 'containers/rest/ReferenceInput';
import RestSelect from 'components/RestInput/RestSelect';
import RecruitmentAddress from './RecruitmentAddress';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import RestHiddenInput from 'components/RestInput/RestHiddenInput';
import { CITIES, SALARY_PERIOD, SALARY_TYPES, VIETNAME_CITIES } from 'data';
import RestFormDateTimePicker from 'components/RestInput/RestDateTimePicker';
import RestEditor from 'components/RestInput/RestEditor';

export const formatOnSubmit = (v) => ({
  ...v,
  startDate: v?.startDate ? v?.startDate.toISOString() : null,
  endDate: v?.starDate ? v?.endDate.toISOString() : null,
});

const RecruitmentsForm = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Card>
        <RestInputItem source="header" header="recruitments.head" />
        <RestInputItem source="title" header="recruitments.title" />

        <RestInputItem required source="companyId" isReference>
          <RestReferenceInput required resource="companies" source="companyId">
            <RestSelect
              header="recruitments.companyId"
              source="companyId"
              valueProp="id"
              titleProp="name"
              required
            />
          </RestReferenceInput>
        </RestInputItem>
        <RestInputItem source="workingTypeId" isReference>
          <RestReferenceInput
            defaultOptions={{ customApiResource: 'working-types' }}
            resource="workingTypes"
            source="workingTypeId"
          >
            <RestSelect
              header="recruitments.workingTypeId"
              source="workingTypeId"
              valueProp="id"
              titleProp="name"
            />
          </RestReferenceInput>
        </RestInputItem>
        <RestInputItem source="languageId" isReference>
          <RestReferenceInput resource="languages" source="languageId">
            <RestSelect
              header="recruitments.languageId"
              source="languageId"
              valueProp="id"
              titleProp="name"
            />
          </RestReferenceInput>
        </RestInputItem>
        <RecruitmentAddress />
        <RestInputItem
          ruleType="number"
          source="numberSlot"
          ContentComponent={InputNumber}
          header="recruitments.numberSlot"
        />
        <Form.Item label={t('recruitments.applyTime')}>
          <Row gutter={[16, 16]}>
            <Col md={12}>
              <div className="d-flex align-center fw-800">
                <div className="mr-10">{t('recruitments.startDate')}</div>
                <RestFormDateTimePicker
                  isShowTime={false}
                  className="ml-10"
                  source="startDate"
                  noStyle
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="d-flex align-center fw-800">
                <div className="mr-10">{t('recruitments.endDate')}</div>
                <RestFormDateTimePicker
                  isShowTime={false}
                  noStyle
                  source="endDate"
                />
              </div>
            </Col>
          </Row>
        </Form.Item>
        <RestInputItem source="email" header="recruitments.email" />
        <RestInputItem source="phoneNumber" header="recruitments.phoneNumber" />
      </Card>
      <br />
      <Card>
        <RestInputItem
          ruleType="number"
          ContentComponent={InputNumber}
          source="priority"
          header="recruitments.priority"
        />
        <RestEditor source="description" header="recruitments.description" />
        <RestInputItem source="experienceLevelId" isReference>
          <RestReferenceInput
            resource="experienceLevels"
            source="experienceLevelId"
            defaultOptions={{ customApiResource: 'experience-levels' }}
          >
            <RestSelect
              header="recruitments.experienceLevelId"
              source="experienceLevelId"
              valueProp="id"
              titleProp="name"
            />
          </RestReferenceInput>
        </RestInputItem>
        <RestInputItem
          source="metaData.educate"
          header="recruitments.educate"
        />
        <RestEditor source="metaData.skill" header="recruitments.skill" />
        <RestEditor
          source="metaData.attitude"
          header="recruitments.attitude"
        /> 
        <RestEditor
          source="metaData.time"
          header="recruitments.time"
        />
        <Row gutter={[16, 16]}>
          <Col md={12}>
          <RestSelect 
              valueProp="value"
              titleProp="text"
              resourceData={SALARY_PERIOD} source='salaryPeriod' 
              header="recruitments.salary"></RestSelect>
            <Row gutter={[16, 16]}>
                  <Col
                    span={12}
                  >
                    <RestInputItem
                      ruleType="number"
                      ContentComponent={InputNumber}
                      source="minSalary"
                      placeholder='recruitments.minSalary'
                    />
                  </Col><Col span={12}>
                      <RestInputItem
                        ruleType="number"
                        ContentComponent={InputNumber}
                        source="maxSalary"
                        placeholder="recruitments.maxSalary"
                      />
                    </Col>
                </Row>
          </Col>
          <Col md={12}>
            <Form.Item label=" ">
              <div className="d-flex ">
                <RestInputItem
                  valuePropName="checked"
                  ruleType="boolean"
                  ContentComponent={Checkbox}
                  source="metaData.hasSalaryKpi"
                  noStyle
                />
                <div className="ml-10">{t('recruitments.salaryKpi')}</div>
              </div>
            </Form.Item>
            <Form.Item
              shouldUpdate={(o, n) =>
                get(o, 'metaData.hasSalaryKpi') !==
                get(n, 'metaData.hasSalaryKpi')
              }
            >
              {({ getFieldValue }) =>
                getFieldValue('metaData.hasSalaryKpi') ? (
                  <RestInputItem
                    source="salaryKpi"
                    placeholder="recruitments.salaryKpiDesc"
                  />
                ) : (
                  <RestHiddenInput
                    source="salaryKpi"
                    header="recruitments.salaryKpi"
                  />
                )
              }
            </Form.Item>
          </Col>
        </Row>
        <RestInputItem source="salaryStepId" isReference>
          <RestReferenceInput
            resource="salarySteps"
            source="salaryStepId"
            defaultOptions={{ customApiResource: 'salary-steps' }}
          >
            <RestSelect
              header="recruitments.salaryStepId"
              source="salaryStepId"
              valueProp="id"
              titleProp="name"
            />
          </RestReferenceInput>
        </RestInputItem>
        <RestEditor source="metaData.benefit" header="recruitments.benefit" />
        <RestInputItem source="salaryBonus" header="recruitments.salaryBonus" />
        <RestSelect
          valueProp="name"
          titleProp="name"
          resourceData={VIETNAME_CITIES}
          source="city"
          header="recruitments.city"
        />
        <RestInputItem
          valuePropName="checked"
          ruleType="boolean"
          ContentComponent={Switch}
          source="isActive"
          header="recruitments.isActive"
        />
      </Card>
    </div>
  );
};

RecruitmentsForm.propTypes = {};

export default RecruitmentsForm;
