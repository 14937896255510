import RestInputItem from 'components/RestInput/RestInputItem';
import { Input, InputNumber, Switch } from 'antd';

const CompanySizesForm = props => (
  <div {...props}>
    <RestInputItem source="name" header="companySizes.name" />
    <RestInputItem
      ContentComponent={Input.TextArea}
      source="description"
      header="industries.description"
    />
    <RestInputItem
      ruleType="number"
      ContentComponent={InputNumber}
      source="priority"
      header="industries.priority"
    />
    <RestInputItem
      valuePropName="checked"
      ruleType="boolean"
      ContentComponent={Switch}
      source="isActive"
      header="industries.isActive"
    />
  </div>
);

CompanySizesForm.propTypes = {};

export default CompanySizesForm;
