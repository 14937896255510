import FooterMenus from './FooterMenus';
import { FooterStyles } from './styles';

export default  function Footer({ children }) {
  return (
    <FooterStyles>
      <div className="main-footer wrapper-container">
        <FooterMenus />
        {children}
      </div>
    </FooterStyles>
  );
}
