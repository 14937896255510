import styled from 'styled-components';

export default styled.div`
  text-align: left;
    width: 50%;
  height: 100%;
  max-width:600px;
  display: flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  .ant-card {
    border: 1px solid var(--bg-primary);
  }
  .logo {
    width: 50px;
    height: 50px;
  }
  .title {
    /* padding: 50px 0 20px; */
    & span {
      font-size: 35px;
      font-weight: 600;
      line-height: 1;
      color: ${({ theme }) => theme.text.secondary};
    }
    .highlight {
      color: ${({ theme }) => theme.palette.primary};
    }
  }

  .sub-title {
    font-size: 14px;
    margin-bottom: 90px;
    color: ${({ theme }) => theme.text.primary};
    max-width: 300px;
  }

  .sub-action-div {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 10px;
  }

  .action-div {
    & button {
      width: 100%;
    }
  }
  .register-form-button {
    margin-top: 10px;
  }
  a.text-highlight {
    color: var(--bg-primary);
  }
`;
