import React from 'react';
import Edit from 'containers/rest/Edit';
import Form from '../components/Form';

const SalaryStepsEdit = props => (
  <Edit {...props} resource="salarySteps">
    <Form />
  </Edit>
);

SalaryStepsEdit.propTypes = {};

export default SalaryStepsEdit;
