import React from 'react';
import Create from 'containers/rest/Create';
import Form from '../components/Form';

const WorkTypesCreate = (props) => (
  <Create {...props} resource="workingTypes">
    <Form />
  </Create>
);

WorkTypesCreate.propTypes = {};

export default WorkTypesCreate;
