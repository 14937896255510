import styled from 'styled-components';

export default styled.div`
  .ant-row {
    .ant-col {
    }
  }
  .ant-form-item {
    padding-bottom: 20px;
  }
`;
