import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';
import { Button, Input, Switch } from 'antd';
import RestEditor from 'components/RestInput/RestEditor';
import { useState } from 'react';
import RestAvatarInput from 'components/RestInput/RestAvatarInput';

const ConfigGeneralsForm = (props) => {
  const [type, setType] = useState('text');
  return (
    <div {...props}>
      <RestInputItem source="key" header="configGenerals.key" />
      <Button.Group className='mb-10'>
        <Button
          type={type === 'text' ? 'primary' : 'default'}
          onClick={() => setType('text')}
        >
          Text
        </Button>
        <Button
          type={type === 'richText' ? 'primary' : 'default'}
          onClick={() => setType('richText')}
        >
          RichText
        </Button>
        <Button
          type={type === 'image' ? 'primary' : 'default'}
          onClick={() => setType('image')}
        >
          Image
        </Button>
      </Button.Group>
      
      {type === 'richText' && (
        <RestEditor source="value" header="configGenerals.value" />
      )}
      {type === 'text' && (
        <RestInputItem
          ContentComponent={Input.TextArea}
          source="value"
          header="configGenerals.value"
        />
      )}
      {type === 'image' && (
        <RestAvatarInput source="value"/>
      )}
      <RestInputItem
        valuePropName="checked"
        ruleType="boolean"
        ContentComponent={Switch}
        source="isActive"
        header="configGenerals.isActive"
      />
    </div>
  );
};

ConfigGeneralsForm.propTypes = {};

export default ConfigGeneralsForm;
