import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';
import { GlobalOutlined } from '@ant-design/icons';

const CompaniesList = (props) => (
  <List {...props} redirects={{}} resource="companies">
    <RestFieldItem source="name" header="companies.name" />
    <RestFieldItem source="industryId" header="companies.industryId" />
    <RestFieldItem width={100} source="companySizeId" header="companies.companySizeId" />
    <RestFieldItem source="address" header="companies.address" />
    <RestFieldItem source="phoneNumber" header="companies.phoneNumber" />
    <RestFieldItem
      format={(d) => (
        <a href={d} target="_blank" rel="noreferrer">
          <GlobalOutlined />
        </a>
      )}
      source="website"
      header="companies.website"
    />
    <RestFieldItem source="isActive" header="companies.isActive" />

    <ActionGroup>
      <EditButton />
      <DeleteButton />
    </ActionGroup>
  </List>
);

CompaniesList.propTypes = {};

export default CompaniesList;
