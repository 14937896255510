import { LOCALES_DATA } from 'configs/localData';
import { isNil, isEmpty, keyBy, omit, get } from 'lodash';
import { getValidData } from 'utils/tools';

export const PRIMARY_KEY = 'id';

export const REQUEST_TYPE = {
  GET_ALL: 'GET_ALL',
  GET_BY_ID: 'GET_BY_ID',
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  DELETE: 'DELETE',
};

export const getFilterInSearch = (key, data) => {
  let filterInSearch = [];
  if (key === 'or') {
    filterInSearch.push({
      $or: data,
    });
    return filterInSearch;
  }
  if (Array.isArray(data)) {
    filterInSearch.push({
      [key]: {
        $in: data,
      },
    });
    return filterInSearch;
  }
  if (typeof data !== 'object' && data) {
    filterInSearch.push({
      [key]: {
        $eq: data,
      },
    });
  } else {
    if (data?.or) {
      const $or = {};
      Object.keys(data.or).forEach((childKey) => {
        $or[`$${childKey}`] = data.or[childKey];
      });
      filterInSearch.push({
        [key]: { $or },
      });
      return filterInSearch;
    }
    if (data?.ne) {
      filterInSearch.push({
        [key]: {
          $ne: data?.ne,
        },
      });
    }
    if (data?.like) {
      filterInSearch.push({
        [key]: {
          $cont: data?.like,
        },
      });
    }
    if (data?.compare) {
      filterInSearch.push({
        [key]: {
          [data?.operator]: data?.compare,
        },
      });
    }
    if (!isNil(data?.isnull)) {
      filterInSearch.push({
        [key]: {
          [data?.isnull ? '$isnull' : '$notnull']: true,
        },
      });
    }
    if (!isNil(data?.notnull)) {
      filterInSearch.push({
        [key]: {
          [data?.notnull ? '$notnull' : '$isnull']: true,
        },
      });
    }
    if (!isNil(data?.in) && !isEmpty(data?.in)) {
      filterInSearch.push({
        [key]: {
          $in: data?.in?.filter((e) => e || e === false),
        },
      });
    }
    if (data?.contL) {
      filterInSearch.push({
        [key]: {
          $contL: data?.contL,
        },
      });
    }
    if (data?.lte) {
      filterInSearch.push({
        [key]: {
          $lte: data?.lte,
        },
      });
    }
    if (data?.gte) {
      filterInSearch.push({
        [key]: {
          $gte: data?.gte,
        },
      });
    }
    if (data?.gt) {
      filterInSearch.push({
        [key]: {
          $gt: data?.gt,
        },
      });
    }
    if (data?.lt) {
      filterInSearch.push({
        [key]: {
          $lt: data?.lt,
        },
      });
    }
    if (data?.contAll) {
      filterInSearch.push({
        [key]: {
          $contAll: data?.contAll,
        },
      });
    }

    if (
      !isEmpty(
        omit(data, [
          'like',
          'isnull',
          'notnull',
          'in',
          'compare',
          'operator',
          'ne',
          'contL',
          'lte',
          'gte',
          'gt',
          'lt',
          'contAll',
        ]),
      )
    ) {
      Object.keys(
        omit(data, [
          'like',
          'isnull',
          'notnull',
          'in',
          'compare',
          'operator',
          'ne',
          'contL',
          'lte',
          'gte',
          'gt',
          'lt',
          'contAll',
        ]),
      ).forEach((subKey) => {
        const subFilters = getFilterInSearch(`${key}.${subKey}`, data[subKey]);
        subFilters.forEach((e) => {
          filterInSearch.push(e);
        });
      });
    }
  }

  return filterInSearch;
};

const getFilterStr = (key, data) => {
  let str = ``;
  if (Array.isArray(data)) {
    return data?.length
      ? `${key}||$in||${data.filter((e) => e || e === false).join(',')}`
      : '';
  }
  if (typeof data !== 'object' && data) {
    str += `${key}||$eq||${data}`;
  } else {
    if (data?.ne) {
      str += `${key}||$ne||${data?.ne}`;
    }
    if (data?.like) {
      str += `${str ? '&' : ''}${key}||$cont||${data?.like}`;
    }
    if (data?.compare) {
      str += `${str ? '&' : ''}${key}||${data?.operator}||${data?.compare}`;
    }
    if (!isNil(data?.isnull)) {
      str += data?.isnull
        ? `${str ? '&' : ''}${key}||$isnull`
        : `${str ? '&' : ''}${key}||$notnull`;
    }
    if (!isNil(data?.notnull)) {
      str += data?.notnull
        ? `${str ? '&' : ''}${key}||$notnull`
        : `${str ? '&' : ''}${key}||$isnull`;
    }
    if (!isNil(data?.in) && !isEmpty(data?.in)) {
      str += `${str ? '&' : ''}${key}||$in||${data?.in?.filter(
        (e) => e || e === false,
      )}`;
    }
    if (data?.contL) {
      str += `${str ? '&' : ''}${key}||$contL||${data?.contL}`;
    }
    if (data?.lte) {
      str += `${str ? '&' : ''}${key}||$lte||${data?.lte}`;
    }
    if (data?.gte) {
      str += `${str ? '&' : ''}${key}||$gte||${data?.gte}`;
    }
    if (data?.gt) {
      str += `${str ? '&' : ''}${key}||$gt||${data?.gt}`;
    }
    if (data?.lt) {
      str += `${str ? '&' : ''}${key}||$lt||${data?.lt}`;
    }
    if (data?.contAll) {
      str += `${str ? '&' : ''}${key}||$contAll||${data?.contAll?.join(',')}`;
    }

    if (
      !isEmpty(
        omit(data, [
          'like',
          'isnull',
          'notnull',
          'in',
          'compare',
          'operator',
          'ne',
          'contL',
          'lte',
          'gte',
          'gt',
          'lt',
          'contAll',
        ]),
      )
    ) {
      Object.keys(
        omit(data, [
          'like',
          'isnull',
          'notnull',
          'in',
          'compare',
          'operator',
          'ne',
          'contL',
          'lte',
          'gte',
          'gt',
          'lt',
          'contAll',
        ]),
      ).forEach((subKey) => {
        str += `&${getFilterStr(`${key}.${subKey}`, data[subKey])}`;
      });
    }
  }

  return str;
};

export const formatFilterInSearch = (rawFilter) => {
  const filter = [];

  Object.keys(getValidData(rawFilter)).forEach((key) => {
    let filters = getFilterInSearch(key, rawFilter[key]);
    filters.forEach((e) => {
      (e || e === false) && filter.push(e);
    });
  });
  return filter.length ? filter : undefined;
};

const formatFilter = (rawFilter) => {
  const filter = [];

  Object.keys(getValidData(rawFilter)).forEach((key) => {
    let str = getFilterStr(key, rawFilter[key]);
    str.split('&').forEach((e) => {
      (e || e === false) && filter.push(e);
    });
  });
  return filter.length ? filter : undefined;
};

const formatSorter = (params = {}) => {
  if (!params.orderBy) return 'updatedAt,DESC';
  if (params.orderBy?.[0] === '-')
    return params.orderBy.substring(1, params.orderBy.length) + ',DESC';
  return params.orderBy + ',ASC';
};

const exceptionConvert = {
  loggers: (params) => {
    return {
      ...getValidData(params.filter || {}, true),
      page: Number((params?.offset || 0) / (params?.limit || 10)) + 1,
      limit: params?.limit || 10,
    };
  },
};

export const convertRequestParams = (
  type = 'GET_ALL',
  params = {},
  resource,
  // options = { primaryKey: PRIMARY_KEY },
) => {
  const { isPassNull, ...requestParams } = params;
  const filterInSearch =
    formatFilterInSearch(
      omit(getValidData(requestParams.filter, true), 'q') || {},
    ) || [];
  const q = requestParams.q ||
    requestParams.filter?.q || { $and: filterInSearch };

  if (requestParams.q) {
    filterInSearch.forEach((e) => {
      q.$and.push(e);
    });
  }

  const formatedParams = exceptionConvert[resource]
    ? exceptionConvert[resource](params)
    : {
        ...omit(requestParams, [
          'q',
          'orderBy',
          'limit',
          'pageSize',
          'offset',
          'count',
          'filter',
        ]),
        page:
          Number((requestParams?.offset || 0) / (requestParams?.limit || 10)) +
          1,
        limit: requestParams?.limit || 10,
        // filter: formatFilter(
        //   omit(getValidData(requestParams.filter, true), 'q') || {},
        // ),
        s: JSON.stringify(q),
        sort: formatSorter(requestParams),
      };
  switch (type) {
    case 'GET_ALL':
      return formatedParams;
    case 'GET_BY_ID':
      return {
        ...requestParams,
      };
    case 'EDIT':
      delete formatedParams.id;
      delete formatedParams.filter;
      delete formatedParams.q;
      delete formatedParams.count;

      return isPassNull
        ? omit(requestParams, ['limit', 'id', 'skip'])
        : getValidData(omit(requestParams, ['limit', 'id', 'skip']));
    case 'CREATE':
      return isPassNull
        ? omit(requestParams, ['limit', 'skip'])
        : getValidData(omit(requestParams, ['limit', 'skip']));
    case 'DELETE':
    default:
      return {};
  }
};

export const convertResponseData = (
  type,
  response,
  options = { primaryKey: PRIMARY_KEY },
) => {
  const tmp = keyBy(get(response || {}, 'detail'), 'langCode');
  const tempDetail = Object.keys(LOCALES_DATA).map((key) => tmp[key]);
  switch (type) {
    case 'GET_ALL':
      return {
        ...response,
        data: keyBy(
          response?.data.map((data, i) => ({
            ...data,
            id: data[options.primaryKey || PRIMARY_KEY] || i,
            backupId: data[PRIMARY_KEY] || i,
          })),
          options.primaryKey || PRIMARY_KEY,
        ),
        ids: response?.data.map(
          (data, i) => data[options.primaryKey || PRIMARY_KEY] || i,
        ),
        total: response?.total,
      };
    case 'GET_BY_ID':
    case 'CREATE':
      return response
        ? {
            ...response,
            id: response[options.primaryKey || PRIMARY_KEY],
            backupId: response[PRIMARY_KEY],
            ...(response?.detail && {
              detail:
                typeof response?.detail === 'string'
                  ? response?.detail
                  : tempDetail,
            }),
          }
        : null;
    case 'EDIT':
      return response && response
        ? {
            ...response,
            id: response[options.primaryKey || PRIMARY_KEY],
            backupId: response[PRIMARY_KEY],
          }
        : null;
    case 'DELETE':
    default:
      return response;
  }
};
