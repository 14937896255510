// import crud action
import { companyRequestsActions as companyRequests } from './companyRequests/actions';
import { applicationsActions as applications } from './applications/actions';
import { companySizesActions as companySizes } from './companySizes/actions';
import { industriesActions as industries } from './industries/actions';
import { eventsActions as events } from './events/actions';
import { configGeneralsActions as configGenerals } from './configGenerals/actions';
import { bannersActions as banners } from './banners/actions';
import { experienceLevelsActions as experienceLevels } from './experienceLevels/actions';
import { languagesActions as languages } from './languages/actions';
import { salaryStepsActions as salarySteps } from './salarySteps/actions';
import { recruitmentsActions as recruitments } from './recruitments/actions';
import { workTypesActions as workingTypes } from './workingTypes/actions';
import { reviewsActions as reviews } from './reviews/actions';
import { companiesActions as companies } from './companies/actions';
import { usersActions as users } from './users/actions';

export default {
  // actions here
  companyRequests,
  applications,
  companySizes,
  industries,
  events,
  configGenerals,
  banners,
  experienceLevels,
  languages,
  salarySteps,
  recruitments,
  workingTypes,
  reviews,
  companies,
  users,
};
