import { Col, Space } from 'antd';
import { useTranslation } from 'react-i18next';

const socialMedias = [
  { icon: '/icons/facebook.png', url: '' },
  { icon: '/icons/email.png', url: '' },
  { icon: '/icons/phone.png', url: '' },
];

export default function FooterMenuColumn({ menu, isShowFollowUs }) {
  const { t } = useTranslation('footer');
  return (
    <Col md={12} sm={12} xs={12}>
      <div className="section-title">{menu?.name}</div>
      <Space className="menu-space-item mt-15" direction="vertical" size={12}>
        {menu?.subMenus?.map((url, index) => (
          <a
            className="link"
            key={`subMenu-${String(`${index}`)}`}
            href={url?.path || '#'}
          >
            <span className="pointer size-m">{url?.name}</span>
          </a>
        ))}
      </Space>
      {isShowFollowUs && (
        <div className="d-flex align-center mt-30">
          <div className="section-title mr-20">{t('followUs')}</div>
          <Space size={17}>
            {socialMedias?.map((socialMedia, i) => (
              <a
                key={socialMedia.link}
                href={socialMedia?.link}
                target="_blank"
                rel="noreferrer"
                className="icon-social"
              >
                <img
                  height="24px"
                  width="24px"
                  src={socialMedia.icon}
                  alt={`social-media-${i + 1}`}
                />
              </a>
            ))}
          </Space>
        </div>
      )}
    </Col>
  );
}
