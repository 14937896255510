import PrivateLayout from 'layout/PrivateLayout';
import CompanyRequests from 'pages/CompanyRequests';
import Applications from 'pages/Applications';
import CompanySizes from 'pages/CompanySizes';
import Industries from 'pages/Industries';
import Events from 'pages/Events';
import ConfigGenerals from 'pages/ConfigGenerals';
import Banners from 'pages/Banners';
import ExperienceLevels from 'pages/ExperienceLevels';
import Languages from 'pages/Languages';
import SalarySteps from 'pages/SalarySteps';
import Recruitments from 'pages/Recruitments';
import WorkingTypes from 'pages/WorkingTypes';
import Reviews from 'pages/Reviews';
import Companies from 'pages/Companies';
import { flatMap, map } from 'lodash';
import Home from 'pages/Dashboard';
import Profile from 'pages/Profile';
import Settings from 'pages/Settings';
import Users from 'pages/Users';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { checkRole } from 'utils/tools';
// import Contacts from 'pages/Contacts';
import DevelopmentPage from 'pages/Development';

const routes = [

  {
    path: '/companyRequests',
    routes: [
      {
        path: '/',
        component: CompanyRequests.List,
      },
      {
        path: '/create',
        component: CompanyRequests.Create,
      },
      {
        path: '/:id/show',
        component: CompanyRequests.Show,
      },
      {
        path: '/:id/edit',
        component: CompanyRequests.Edit,
      },
    ],
  },

  {
    path: '/applications',
    routes: [
      {
        path: '/',
        component: Applications.List,
      },
      {
        path: '/create',
        component: Applications.Create,
      },
      {
        path: '/:id/show',
        component: Applications.Show,
      },
      {
        path: '/:id/edit',
        component: Applications.Edit,
      },
    ],
  },
  {
    path: '/events',
    routes: [
      {
        path: '/',
        component: Events.List,
      },
      {
        path: '/create',
        component: Events.Create,
      },
      {
        path: '/:id/show',
        component: Events.Show,
      },
      {
        path: '/:id/edit',
        component: Events.Edit,
      },
    ],
  },

  {
    path: '/recruitments',
    routes: [
      {
        path: '/',
        component: Recruitments.List,
      },
      {
        path: '/create',
        component: Recruitments.Create,
      },
      {
        path: '/:id/show',
        component: Recruitments.Show,
      },
      {
        path: '/:id/edit',
        component: Recruitments.Edit,
      },
    ],
  },

  {
    path: '/reviews',
    routes: [
      {
        path: '/',
        component: Reviews.List,
      },
      {
        path: '/create',
        component: Reviews.Create,
      },
      {
        path: '/:id/show',
        component: Reviews.Show,
      },
      {
        path: '/:id/edit',
        component: Reviews.Edit,
      },
    ],
  },

  {
    path: '/companies',
    routes: [
      {
        path: '/',
        component: Companies.List,
      },
      {
        path: '/create',
        component: Companies.Create,
      },
      {
        path: '/:id/show',
        component: Companies.Show,
      },
      {
        path: '/:id/edit',
        component: Companies.Edit,
      },
    ],
  },
  {
    path: '/users',
    routes: [
      {
        path: '/',
        component: Users.List,
      },
      {
        path: '/create',
        component: Users.Create,
      },
      {
        path: '/:id/edit',
        component: Users.Edit,
      },
      {
        path: '/:id/show',
        component: Users.Show,
      },
    ],
  },
  {
    path: '/profile',
    component: Profile,
    exact: true,
    title: 'profile.title',
  },
  {
    path: '/settings',
    exact: true,
    title: 'settings.title',
    hasPrivateLayoutWrapper: true,
    routes: [
      {
        path: '/',
        component: Settings,
        exact: true,
        title: 'settings.title',
      },

      {
        path: '/companySizes',
        routes: [
          {
            path: '/',
            component: CompanySizes.List,
          },
          {
            path: '/create',
            component: CompanySizes.Create,
          },
          {
            path: '/:id/show',
            component: CompanySizes.Show,
          },
          {
            path: '/:id/edit',
            component: CompanySizes.Edit,
          },
        ],
      },
      {
        path: '/industries',
        routes: [
          {
            path: '/:id/show',
            component: Industries.Show,
          },
          {
            path: '/:id/edit',
            component: Industries.Edit,
          },
          {
            path: '/create',
            component: Industries.Create,
          },
          {
            path: '/',
            component: Industries.List,
          },
        ],
      },
      {
        path: '/configGenerals',
        routes: [
          {
            path: '/:id/show',
            component: ConfigGenerals.Show,
          },
          {
            path: '/:id/edit',
            component: ConfigGenerals.Edit,
          },
          {
            path: '/create',
            component: ConfigGenerals.Create,
          },
          {
            path: '/',
            component: ConfigGenerals.List,
          },
        ],
      },

      {
        path: '/banners',
        routes: [
          {
            path: '/:id/show',
            component: Banners.Show,
          },
          {
            path: '/:id/edit',
            component: Banners.Edit,
          },
          {
            path: '/create',
            component: Banners.Create,
          },
          {
            path: '/',
            component: Banners.List,
          },
        ],
      },

      {
        path: '/experienceLevels',
        routes: [
          {
            path: '/:id/show',
            component: ExperienceLevels.Show,
          },
          {
            path: '/:id/edit',
            component: ExperienceLevels.Edit,
          },
          {
            path: '/create',
            component: ExperienceLevels.Create,
          },
          {
            path: '/',
            component: ExperienceLevels.List,
          },
        ],
      },

      {
        path: '/languages',
        routes: [
          {
            path: '/:id/show',
            component: Languages.Show,
          },
          {
            path: '/:id/edit',
            component: Languages.Edit,
          },
          {
            path: '/create',
            component: Languages.Create,
          },
          {
            path: '/',
            component: Languages.List,
          },
        ],
      },

      {
        path: '/salarySteps',
        routes: [
          {
            path: '/:id/show',
            component: SalarySteps.Show,
          },
          {
            path: '/:id/edit',
            component: SalarySteps.Edit,
          },
          {
            path: '/create',
            component: SalarySteps.Create,
          },
          {
            path: '/',
            component: SalarySteps.List,
          },
        ],
      },
      {
        path: '/workingTypes',
        routes: [
          {
            path: '/:id/show',
            component: WorkingTypes.Show,
          },
          {
            path: '/:id/edit',
            component: WorkingTypes.Edit,
          },
          {
            path: '/create',
            component: WorkingTypes.Create,
          },
          {
            path: '/',
            component: WorkingTypes.List,
          },
        ],
      },
    ],
  },
  {
    path: '/',
    component: Home,
    exact: true,
    title: 'dashboard.title',
    hasPrivateLayoutWrapper: true,
  },
  {
    path: '/development',
    component: DevelopmentPage,
    exact: true,
    title: 'DEVELOPMENT',
    hasPrivateLayoutWrapper: true,
  },
];

const wrappedRoutes = map(
  flatMap(routes, (route) => {
    if (route.routes) {
      const _routes = [];
      route.routes.forEach((e) => {
        if (e.routes) {
          e.routes.forEach((child) => {
            _routes.push({
              ...child,
              path: `${e.path}${child.path}`,
            });
          });
        } else {
          _routes.push(e);
        }
      });
      return map(_routes, (subRoute) => ({
        ...subRoute,
        path: route.path + subRoute.path,
        exact: subRoute.path === '/',
        hasPrivateLayoutWrapper:
          subRoute.hasPrivateLayoutWrapper !== undefined
            ? subRoute.hasPrivateLayoutWrapper
            : route.hasPrivateLayoutWrapper,
        component: subRoute.component || route.component,
      }));
    }
    return route;
  }),
  (route) => <PrivateRoute {...route} key={route.path} />,
);

function PrivateRoute({
  component: Component,
  title,
  hasPrivateLayoutWrapper,
  roles,
  ...rest
}) {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const role = useSelector((state) => state.auth.role);
  return checkRole(roles, role) ? (
    <Route
      {...rest}
      render={
        (props) =>
          isAuthenticated ? (
            <PrivateLayout
              title={title}
              hasPrivateLayoutWrapper={hasPrivateLayoutWrapper}
            >
              <Component />
            </PrivateLayout>
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                // eslint-disable-next-line
                state: { from: props.location },
              }}
            />
          )
        // eslint-disable-next-line react/jsx-curly-newline
      }
    />
  ) : (
    <Route render={null} />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  title: PropTypes.string,
  hasPrivateLayoutWrapper: PropTypes.bool,
  roles: PropTypes.array,
};
const PrivateRoutes = () => wrappedRoutes;

export default PrivateRoutes;
