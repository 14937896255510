import PublicLayout from '../../layout/PublicLayout';
import LoginForm from '../../containers/Login';
import LoginWrapper from './styles';
import { Card } from 'antd';

export default function Login() {
  return (
    <PublicLayout>
      <LoginWrapper>
        <div className="title">
          <img alt="" src="/static/logo.png" className="logo" />
        </div>
        <Card>
          <LoginForm />
        </Card>
      </LoginWrapper>
    </PublicLayout>
  );
}
