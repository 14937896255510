import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';

const EventsForm = props => (
  <div {...props}>
    <RestInputItem source="title" header="events.title" />
    <RestInputItem source="description" header="events.description" />
    <RestInputItem source="priority" header="events.priority" />
    <RestInputItem source="link" header="events.link" />
    <RestInputItem source="thumbnail" header="events.thumbnail" />
    <RestInputItem source="isActive" header="events.isActive" />
    <RestInputItem source="startDate" header="events.startDate" />
    <RestInputItem source="endDate" header="events.endDate" />
    <RestInputItem source="location" header="events.location" />
    <RestInputItem source="city" header="events.city" />
  </div>
);

EventsForm.propTypes = {};

export default EventsForm;
