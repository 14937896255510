import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';
import { Input,InputNumber,Switch } from 'antd';

const SalaryStepsForm = props => (
  <div {...props}>
    <RestInputItem source="name" header="salarySteps.name" />
    <RestInputItem
      ContentComponent={Input.TextArea}
      source="description"
      header="industries.description"
    />
    <RestInputItem
      ruleType="number"
      ContentComponent={InputNumber}
      source="priority"
      header="industries.priority"
    />
    <RestInputItem
      valuePropName="checked"
      ruleType="boolean"
      ContentComponent={Switch}
      source="isActive"
      header="industries.isActive"
    />
  </div>
);

SalaryStepsForm.propTypes = {};

export default SalaryStepsForm;
