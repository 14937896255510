import { Button, Select, Row, Col, Form, Input, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { register as registerAction } from '@redux/auth/actions';
import { validateRegex } from '../../utils/validateUtils';

const FormItem = Form.Item;

const Register = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const register = (params) => dispatch(registerAction(params));

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      if (values.password === values.comfirm && values.password.length >= 6) {
        register({
          username: values.username,
          email: values.email,
          password: values.password,
        });
      }
    });
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback(t('input.confirmPassword.validateMsg.match'));
    } else {
      callback();
      console.log('err');
    }
  };
  if (isAuthenticated) {
    return <Redirect to="/" />;
  }
  return (
    <Row gutter={[16, 16]}>
      <Col md={14} sm={24} xs={24}>
        <div>
          <img alt="" src="/static/logo.png" />
        </div>
        <Card>
          <h3 className="fw-700 size-xl text-dark-highlight">Tạo tài khoản</h3>
          <div className="fw-700 text-gray size-l">
            Bạn cần tạo tài khoản Nhà tuyển dụng với các thông tin dưới đây để
            hoàn tất hồ sơ và cho các ứng viên biết về công ty bạn
          </div>
          <br />
          <Form form={form} layout="vertical">
            <FormItem
              name="companyName"
              rules={[
                {
                  required: true,
                  message: t('input.companyName.validateMsg.required'),
                },
              ]}
              label={t('input.companyName.label')}
            >
              <Input />
            </FormItem>
            <FormItem
              name="size"
              rules={[
                {
                  required: true,
                  message: t('input.size.validateMsg.required'),
                },
              ]}
              label={t('input.size.label')}
            >
              <Select>
                <Select.Option value="5+">5+</Select.Option>
                <Select.Option value="10+">10+</Select.Option>
                <Select.Option value="50+">50+</Select.Option>
              </Select>
            </FormItem>
            <FormItem
              name="fullName"
              rules={[
                {
                  required: true,
                  message: t('input.fullName.validateMsg.required'),
                },
              ]}
              label={t('input.fullName.label')}
            >
              <Input />
            </FormItem>
            <FormItem
              name="email"
              rules={[
                {
                  required: true,
                  message: t('input.email.validateMsg.required'),
                },
                {
                  type: 'email',
                  message: t('input.email.validateMsg.invalid'),
                },
              ]}
              label={t('input.email.label')}
            >
              <Input />
            </FormItem>
            <FormItem
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: t('input.phoneNumber.validateMsg.required'),
                },
              ]}
              label={t('input.phoneNumber.label')}
            >
              <Input />
            </FormItem>
            <FormItem
              name="title"
              rules={[
                {
                  required: true,
                  message: t('input.title.validateMsg.required'),
                },
              ]}
              label={t('input.title.label')}
            >
              <Input />
            </FormItem>
            <FormItem
              name="password"
              rules={[
                {
                  required: true,
                  message: t('input.password.validateMsg.required'),
                },
                {
                  pattern: validateRegex.password,
                  message: t('error.password'),
                },
              ]}
              label={t('input.password.label')}
            >
              <Input.Password type="password" />
            </FormItem>
            <FormItem
              hasFeedback
              name="comfirm"
              rules={[
                {
                  required: true,
                  message: t('input.confirmPassword.validateMsg.required'),
                },
                { validator: compareToFirstPassword },
              ]}
              label={t('input.confirmPassword.label')}
            >
              <Input.Password type="password" />
            </FormItem>
            <div className="d-flex space-between mt-20">
              <div>
                <span style={{ marginRight: 5 }}>{t('login.question')}</span>
                <Link to="/login">
                <span className='text-highlight'>
                {t('login.loginBtn')}
                </span>
                </Link>
              </div>
              <Button
                onClick={handleSubmit}
                type="primary"
                className="login-form-button"
              >
                {t('button.saveAndContinue')}
              </Button>
            </div>
          </Form>
        </Card>
      </Col>
      <Col md={10} sm={0} xs={0} className="h-full align-self-center">
        <div className="flex-center h-full">
          <img src="/static/images/register-image.png" />
        </div>
      </Col>
    </Row>
  );
};

Register.propTypes = {};

export default Register;
