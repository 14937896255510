'use client';

import styled from 'styled-components';
import {Card} from 'antd';

const JobCardStyles = styled(Card)`
  .job-card-title {
    font-size: 18;
    color: var(--bg-primary);
    font-weight: 800;
    min-width: 300px;
    margin-bottom:0px;
  }
  .job-card-company {
    font-size: 16px;
    color: var(--bg-primary);
    margin-bottom:0px;
  }
  .job-card-address {
    font-size: 15px;
    margin-top:0px;
    color:var(--text-description);
  }

  .salary-label {
    background: #c8c5c54d;
    font-weight: 600;
    font-size: 14px;
    border-radius: 7px;
    color:var(--text-description);
    display:inline-flex;
    align-items:center;
    padding: 5px 10px;
    justify-content: flex-start;
  }
  .salary-icon {
    margin-right: 10px;
  }

  .job-card-time {
font-size: 12px;
color:var(--text-description);
margin-top: 10px;
  }
  .view-more {
    color:var(--text-description);
    font-size: 12px;
  }

  .btn-apply {
    align-self: flex-end;
    font-weight: 800;
  }
  .dot {
    margin:0px 20px;
  }
`;

export default JobCardStyles;
