import { Card, Divider } from 'antd';
import { formatStatus } from 'components/RestField/RestFieldItem';
import { useTranslation } from 'react-i18next';
import { formatDate, formatMoney } from 'utils/textUtils';

export default function RecruitmentInformation({ record }) {
  const { t } = useTranslation();
  const SUMMARIES = [
    {
      key: 0,
      label: 'recruitments.applyTime',
      value: `${formatDate(record?.startDate)} - ${formatDate(
        record?.endDate,
      )}`,
    },
    {
      key: 0,
      label: 'recruitments.fixedSalary',
      value:
        record?.minSalary !== record?.maxSalary && record?.maxSalary
          ? ` ${formatMoney(record?.minSalary)} - ${formatMoney(
              record?.maxSalary,
            )} + 5% doanh thu`
          : ` ${formatMoney(record?.minSalary)} + ${record?.salaryKpi}`,
    },
    {
      key: 1,
      label: 'recruitments.title',
      value: record?.title,
    },
    {
      key: 2,
      label: 'recruitments.salaryBonus',
      value: record?.salaryBonus,
    },
    {
      key: 1,
      label: 'recruitments.numberSlot',
      value: record?.numberSlot,
    },
    {
      key: 1,
      label: 'recruitments.city',
      value: record?.city,
    },
    {
      key: 1,
      label: 'recruitments.email',
      value: record?.email,
    },
    {
      key: 1,
      label: 'recruitments.phoneNumber',
      value: record?.phoneNumber,
    },
    {
      key: 1,
      label: 'recruitments.title',
      value: record?.title,
    },
  ];
  return (
    <Card title={record?.header} extra={formatStatus(record?.isActive)}>
      <br />
      <div>
        {SUMMARIES.map((e) => (
          <div key={e.key}>
            <div className="d-flex space-between">
              <div>
                <b>{t(e.label)}</b>
              </div>
              <div>{e.value}</div>
            </div>
            <Divider />
          </div>
        ))}
        <p dangerouslySetInnerHTML={{__html:record?.description}}></p>
      </div>
    </Card>
  );
}
