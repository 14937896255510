import { Select } from 'antd';
import RestFieldItem from 'components/RestField/RestFieldItem';
import List from 'containers/rest/List';

const CompanyRequestsList = (props) => (
  <List {...props} resource="companyRequests">
    <RestFieldItem
      width={150}
      source="fullName"
      header="companyRequests.fullName"
    />
    <RestFieldItem
      width={100}
      source="phoneNumber"
      header="companyRequests.phoneNumber"
    />
    <RestFieldItem width={150} source="email" header="companyRequests.email" />
    <RestFieldItem
      width={100}
      source="companyName"
      header="companyRequests.companyName"
    />
    <RestFieldItem width={100} source="title" header="companyRequests.title" />
    <RestFieldItem width={100} source="city" header="companyRequests.city" />
    <RestFieldItem
      width={150}
      source="title_recruitment"
      header="companyRequests.title_recruitment"
    />
    <RestFieldItem
      width={150}
      source="numberSlot"
      header="companyRequests.numberSlot"
    />
    <RestFieldItem
      width={150}
      source="talentService"
      header="companyRequests.talentService"
    />
    <RestFieldItem
      valueProp="value"
      fixed='right'
      component={
        <Select
          options={[
            { label: 'Pending', value: 'pending' },
            { label: 'Completed', value: 'complated' },
          ]}
        ></Select>
      }
      source="status"
      header="companyRequests.status"
    />
  </List>
);

CompanyRequestsList.propTypes = {};

export default CompanyRequestsList;
