import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const CompanySizesShow = props => (
  <RestShow {...props} hasEdit resource="companySizes">
    <RestFieldItem source="name" header="companySizes.name" />
    <RestFieldItem source="description" header="companySizes.description" />
  </RestShow>
);

export default CompanySizesShow;
