import List from 'containers/rest/List';
import JobCard from 'containers/Recruitments/components/JobCard';

const initialFilter = {
  join:['company'],
}
const Home = ({ props }) => {
  return (
    <List isList {...props} grid={{
      gutter: 16,
      xs: 1,
      sm: 2,
      md: 2,
      lg: 2,
      xl: 2,
      xxl: 2,
    }} initialFilter={initialFilter} redirects={{}} resource="recruitments">
      <JobCard />
    </List>
  );
};
Home.propTypes = {};

export default Home;
