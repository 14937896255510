import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const SalaryStepsShow = props => (
  <RestShow {...props} hasEdit resource="salarySteps">
    <RestFieldItem source="name" header="salarySteps.name" />
    <RestFieldItem source="description" header="salarySteps.description" />
    <RestFieldItem source="priority" header="salarySteps.priority" />
    <RestFieldItem source="isActive" header="salarySteps.isActive" />
  </RestShow>
);

export default SalaryStepsShow;
