import React from 'react';
import Create from 'containers/rest/Create';
import Form from '../components/Form';

const ExperienceLevelsCreate = props => (
  <Create {...props} resource="experienceLevels">
    <Form />
  </Create>
);

ExperienceLevelsCreate.propTypes = {};

export default ExperienceLevelsCreate;
