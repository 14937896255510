import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const ExperienceLevelsShow = props => (
  <RestShow {...props} hasEdit resource="experienceLevels">
    <RestFieldItem source="name" header="experienceLevels.name" />
    <RestFieldItem source="description" header="experienceLevels.description" />
    <RestFieldItem source="priority" header="experienceLevels.priority" />
    <RestFieldItem source="isActive" header="experienceLevels.isActive" />
  </RestShow>
);

export default ExperienceLevelsShow;
