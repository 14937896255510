import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const EventsShow = props => (
  <RestShow {...props} hasEdit resource="events">
    <RestFieldItem source="title" header="events.title" />
    <RestFieldItem source="description" header="events.description" />
    <RestFieldItem source="priority" header="events.priority" />
    <RestFieldItem source="link" header="events.link" />
    <RestFieldItem source="thumbnail" header="events.thumbnail" />
    <RestFieldItem source="isActive" header="events.isActive" />
    <RestFieldItem source="startDate" header="events.startDate" />
    <RestFieldItem source="endDate" header="events.endDate" />
    <RestFieldItem source="location" header="events.location" />
    <RestFieldItem source="city" header="events.city" />
  </RestShow>
);

export default EventsShow;
