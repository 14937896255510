import RestInputItem from 'components/RestInput/RestInputItem';
import { Input, InputNumber, Switch } from 'antd';
import RestAvatarInput from 'components/RestInput/RestAvatarInput';

const BannersForm = props => (
  <div {...props}>
    <RestInputItem source="name" header="banners.name" />
    <RestAvatarInput isFullWidth header="Image(Size 1940x540)" source="image"/>
    <RestInputItem source="url" header="banners.url" />
    <RestInputItem
      ContentComponent={Input.TextArea}
      source="description"
      header="industries.description"
    />
    <RestInputItem
      ruleType="number"
      ContentComponent={InputNumber}
      source="priority"
      header="industries.priority"
    />
    <RestInputItem
      valuePropName="checked"
      ruleType="boolean"
      ContentComponent={Switch}
      source="isActive"
      header="industries.isActive"
    />
  </div>
);

BannersForm.propTypes = {};

export default BannersForm;
