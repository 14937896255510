import { EditFilled } from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';
import RestHiddenInput from 'components/RestInput/RestHiddenInput';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { useContext } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function RecruitmentAddress() {
  const { t } = useTranslation();
  const { form } = useContext(RestInputContext);
  const [visible, setVisible] = useState();
  return (
    <div>
      <RestHiddenInput
        source="metaData.address"
        header="recruitments.address"
      />
      <Form.Item>
      <div className="d-flex flex-center">
        <div className="mr-40">
          <b>{t('recruitments.address')}</b>
        </div>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <div className="text-highlight">
              {getFieldValue('metaData.address')}
            </div>
          )}
        </Form.Item>
        <div className="flex-1" />
        <Button
          onClick={() => setVisible(true)}
          type="primary"
          icon={<EditFilled />}
          size="small"
        ></Button>
      </div>
      </Form.Item>
      <Modal
        title={t('recruitments.address')}
        open={visible}
        onCancel={() => setVisible(false)}
        destroyOnClose
        footer={null}
      >
        <Input
          defaultValue={form.getFieldValue('metaData.address')}
          onChange={(e) =>
            form.setFieldsValue({
              'metaData.address': e.target.value,
            })
          }
        />
      </Modal>
    </div>
  );
}
