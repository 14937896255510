import React from 'react';
import Edit from 'containers/rest/Edit';
import Form, { formatOnSubmit } from '../components/Form';

const RecruitmentsEdit = (props) => (
  <Edit {...props} formatOnSubmit={formatOnSubmit} resource="recruitments">
    <Form />
  </Edit>
);

RecruitmentsEdit.propTypes = {};

export default RecruitmentsEdit;
